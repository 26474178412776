import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private authService: AuthService, private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		//console.log(this.authService.isLoggedIn(), state.url);
		//console.log(localStorage.getItem('JWT_TOKEN'), "qwertyuil");
		if (!this.authService.isLoggedIn() && state.url != '/auth/signin') {
			return this.router.navigate(['/auth/signin']);
		}
		else if (!this.authService.isLoggedIn() && state.url == '/auth/signin') {
			return true
		}
		else if (this.authService.isLoggedIn() && state.url != '/auth/signin') {
			return true;
		}
		else if (this.authService.isLoggedIn() && state.url == '/auth/signin') {
			return this.router.navigate(['/dashboard/analytics']);
		}
	}
}
